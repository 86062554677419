import { template as template_745202b6d9dd488dabcbe16355fc3f3d } from "@ember/template-compiler";
const FKLabel = template_745202b6d9dd488dabcbe16355fc3f3d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
