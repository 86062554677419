import { template as template_3310e539ed2d46b9b92e2bfab16e0dff } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_3310e539ed2d46b9b92e2bfab16e0dff(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
