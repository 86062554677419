import { template as template_b2412270bb054bbbb6b0557903329719 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b2412270bb054bbbb6b0557903329719(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
